export interface Author {
  id: number;
  name: string;
  image: string;
  about: string;
}

export interface BlogTag {
  id: number;
  title: string;
}

export class BlogCategory {
  id: number;
  title: string;
  sequence: number;
  blogs: number[];
  topBlogId: number = -1;

  public static fromJson(json: any): BlogCategory {
    const blog = new BlogCategory();
    blog.id = json['id'];
    blog.title = json['title'];
    blog.sequence = json['sequence'];
    blog.blogs = json['blogs'];
    if (blog.blogs.length) {
      blog.topBlogId = blog.blogs[0];
    }
    return blog;
  }

  public static fromList(list: any = []): BlogCategory[] {
    const categories: BlogCategory[] = [];
    for (const json of list) {
      categories.push(BlogCategory.fromJson(json));
    }
    return categories;
  }
}

export class BlogDetails {
  id: number;
  authorId: number;
  title: string;
  description: string;
  content: string;
  media: string;
  mediaType: string;
  readTime: number;
  authorName: string;
  tags: number[];
  createdOn: number;

  public static fromJson(json: any): BlogDetails {
    const blog = new BlogDetails();
    blog.id = json['id'];
    blog.authorId = json['author_id'];
    blog.title = json['title'];
    blog.description = json['description'];
    blog.content = json['content'];
    blog.media = json['media'];
    blog.mediaType = json['media_type'];
    blog.readTime = json['config']['readTime'] ?? 1;
    blog.authorName = json['author_name'];
    blog.tags = json['tag_ids'] ?? [];
    blog.createdOn = json['created_utc_millis'];
    return blog;
  }
}

export class BlogItem {
  id: number;
  title: string;
  description: string;
  authorName: string;
  authorImage: string;
  authorAbout: string;
  content: string;
  media: string;
  mediaType: string;
  tags: number[];
  createOn: number;
  readTime: number;

  public static fromJson(json: any): BlogItem {
    const blog = new BlogItem();
    blog.id = json['id'];
    blog.authorName = json['author_name'];
    blog.authorImage = json['author_image'];
    blog.authorAbout = json['author_about'];
    blog.title = json['title'];
    blog.description = json['description'];
    blog.content = json['content'];
    blog.media = json['media'];
    blog.mediaType = json['media_type'];
    blog.createOn = json['created_utc_millis'];
    blog.tags = json['tag_ids'] ?? [];
    blog.readTime = json['config']['readTime'] ?? 1;
    return blog;
  }

  get slug() {
    const title = this.title
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');
    return `${title}-${this.id}`;
  }

  get link() {
    return `/blogs/details/${this.slug}`;
  }
}
